import React from "react";
import "./BackHeader.css";
import Messenger from "../../assets/Header/messenger-logo.svg";
import Telegram from "../../assets/Header/tg-logo.svg";
import backIcon from "../../assets/PlayerAccount/back-icon.svg";

const Header = ({ displayText, onBackClick }) => {
  return (
    <header className="header-container">
      <div className="text-section">
        {/* Ensure the onBackClick is properly attached to the img element */}
        <img
          src={backIcon}
          alt="Back"
          className="back-icon"
          onClick={onBackClick}
        />
        <span>{displayText}</span>
      </div>
      <div className="action-buttons-section">
        <a
          href="https://web.facebook.com/profile.php?id=61567510295878&mibextid=ZbWKwL&_rdc=1&_rdr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Messenger} alt="Chat" className="b-chat-btn" />
        </a>
        <a
          href="https://t.me/+h1lh_ANBgg1jZDY1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Telegram} alt="How to Bet" className="b-how-to-bet-btn" />
        </a>
      </div>
    </header>
  );
};

export default Header;
