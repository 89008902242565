import React, { useEffect, useState, useCallback } from "react";
import "./RaceNumber.css";
import useEventsStore from "../../../../store/useEventStore";

const RaceNumber = ({ selectedBetType, expectedRaces, onRaceRangeSelect }) => {
  const { pick5set, pick6set, wtasset } = useEventsStore();
  const [raceNumbers, setRaceNumbers] = useState([]);
  const [activeRace, setActiveRace] = useState(null);
  const [previousBetType, setPreviousBetType] = useState(null);

  const handleRaceRangeSelect = useCallback(
    (race) => {
      onRaceRangeSelect(race);
      setActiveRace(race);
    },
    [onRaceRangeSelect]
  );

  const generateRaceNumbers = useCallback((betType) => {
    switch (betType) {
      case "DD":
        return Array.from(
          { length: expectedRaces - 1 },
          (_, i) => `${i + 1} & ${i + 2}`
        );
      case "FC":
      case "W":
        return Array.from({ length: expectedRaces }, (_, i) =>
          (i + 1).toString()
        );
      case "XD":
        return Array.from(
          { length: expectedRaces - 2 },
          (_, i) => `${i + 1} & ${i + 3}`
        );
      case "DD+1":
        return Array.from(
          { length: expectedRaces - 2 },
          (_, i) => `${i + 1} - ${i + 3}`
        );
      case "PICK5":
        return pick5set || [];
      case "PICK6":
        return pick6set || [];
      case "WTA":
        return wtasset || [];
      default:
        return [];
    }
  }, [expectedRaces, pick5set, pick6set, wtasset]);

  useEffect(() => {
    if (selectedBetType && expectedRaces) {
      const numbers = generateRaceNumbers(selectedBetType);
      setRaceNumbers(numbers);

      // Reset to first race if bet type changes
      if (selectedBetType !== previousBetType) {
        handleRaceRangeSelect(numbers[0]);
        setPreviousBetType(selectedBetType);
      } else if (numbers.length > 0) {
        setActiveRace((prevActiveRace) => {
          if (!prevActiveRace || !numbers.includes(prevActiveRace)) {
            handleRaceRangeSelect(numbers[0]);
            return numbers[0];
          }
          return prevActiveRace;
        });
      }
    }
  }, [
    selectedBetType,
    expectedRaces,
    generateRaceNumbers,
    handleRaceRangeSelect,
    previousBetType,
  ]);

  return (
    <div className="race-number-buttons">
      {raceNumbers.map((race, index) => (
        <button
          key={index}
          className={`race-number-button ${
            activeRace === race ? "active" : ""
          }`}
          onClick={() => handleRaceRangeSelect(race)}
        >
          {race}
        </button>
      ))}
    </div>
  );
};

export default RaceNumber;
