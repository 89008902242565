import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./LoginSection.css";
import GradientButton from "../Button/GradientButton";
import { useAuthStore } from "../../store/useAuthStore";

const LoginSection = () => {
  const [activeTab, setActiveTab] = useState("login");
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [isReferralCodeDisabled, setIsReferralCodeDisabled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [registerDisabled, setRegisterDisabled] = useState(true);
  const [localError, setLocalError] = useState(null);
  const [showMobileWarning, setShowMobileWarning] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const {
    login,
    isAuthenticated,
    error,
    loginLoading,
    register,
    registerLoading,
  } = useAuthStore();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/race");
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const referral = queryParams.get("") || queryParams.get("referral");

    if (referral) {
      setActiveTab("register");
      setReferralCode(referral);
      setIsReferralCodeDisabled(true);
    }
  }, [location]);

  useEffect(() => {
    setIsDisabled(mobileNumber === "" || password === "");
  }, [mobileNumber, password]);

  useEffect(() => {
    setRegisterDisabled(
      firstName === "" ||
        lastName === "" ||
        mobileNumber.length !== 11 ||
        password === "" ||
        password !== confirmPassword ||
        referralCode === ""
    );
  }, [
    firstName,
    lastName,
    mobileNumber,
    password,
    confirmPassword,
    referralCode,
  ]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setLocalError(null);
  };

  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,11}$/.test(value)) {
      setMobileNumber(value);
      if (value.length === 11) {
        setShowMobileWarning(false);
      }
    }
  };

  const handleMobileNumberBlur = () => {
    setShowMobileWarning(mobileNumber.length !== 11);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    await login(mobileNumber, password);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (registerDisabled || registerLoading) return;
    if (password !== confirmPassword) {
      setLocalError("Passwords do not match.");
      return;
    }

    const finalReferralCode = referralCode;
    await register(
      firstName,
      lastName,
      mobileNumber,
      password,
      finalReferralCode
    );
  };


  return (
    <div className="login-section">
      <div className="login-register-tabs">
        <div
          className={`tab ${activeTab === "login" ? "active" : ""}`}
          onClick={() => handleTabClick("login")}
        >
          Login
        </div>

        <div
          className={`tab ${activeTab === "register" ? "active" : ""}`}
          onClick={() => handleTabClick("register")}
        >
          Register
        </div>
      </div>

      <div className="form-container">
        {activeTab === "login" && (
          <form className="login-form" onSubmit={handleLogin}>
            <input
              type="text"
              placeholder="Mobile number*"
              className="input-field"
              value={mobileNumber}
              onChange={handleMobileNumberChange}
              onBlur={handleMobileNumberBlur}
            />
            {showMobileWarning && (
              <div className="warning-message">Mobile number must be 11 digits.</div>
            )}
            <input
              type="password"
              placeholder="Password*"
              className="input-field"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {error && <div className="error-message">{error}</div>}
            <GradientButton
              style={{ marginTop: "8px", marginBottom: "16px" }}
              text={loginLoading ? "Loading..." : "LOGIN"}
              onClick={handleLogin}
              disabled={isDisabled || loginLoading}
            />
            <a href="/forgot-password" className="forgot-password">
              Forgot password?
            </a>
          </form>
        )}

        {activeTab === "register" && (
          <form className="register-form" onSubmit={handleRegister}>
            <input
              type="text"
              placeholder="First Name*"
              className="input-field"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Last Name*"
              className="input-field"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Mobile number*"
              className="input-field"
              value={mobileNumber}
              onChange={handleMobileNumberChange}
              onBlur={handleMobileNumberBlur}
            />
            {showMobileWarning && (
              <div className="warning-message">Mobile number must be 11 digits.</div>
            )}
            <input
              type="password"
              placeholder="Password*"
              className="input-field"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="Confirm Password*"
              className="input-field"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {password !== confirmPassword && confirmPassword && (
              <div className="warning-message">Passwords do not match.</div>
            )}
            <input
              type="text"
              placeholder="Referral Code*"
              className="input-field"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
              disabled={isReferralCodeDisabled}
            />
            {(error || localError) && (
              <div className="error-message">{localError || error}</div>
            )}
            <GradientButton
              style={{
                marginTop: "8px",
                marginBottom: "16px",
                background: registerDisabled
                  ? "#5B5B5B"
                  : "linear-gradient(90deg, #FFF61F 27%, #7BF342 100%)",
                color: registerDisabled ? "#9F9F9F" : undefined,
                cursor: registerDisabled ? "not-allowed" : "pointer",
              }}
              text={registerLoading ? "Registering..." : "REGISTER"}
              disabled={registerDisabled || registerLoading}
            />
          </form>
        )}
      </div>
      <footer className="footer-text">
        <p>Copyright 2024. www.KareraKing.com All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default LoginSection;
