import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { useBetHistoryStore } from "../../../store/useBetHistoryStore";
import "../Transaction.css";
import { CurrencyFormatter } from "../../../formatter/currencyFormatter";
import Pagination from '../../../components/Pagination/Pagination'; // Adjust the import path as needed

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const dateOptions = { month: 'short', day: 'numeric', year: 'numeric' };
  const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
  const formattedDate = date.toLocaleDateString(undefined, dateOptions);
  const formattedTime = date.toLocaleTimeString(undefined, timeOptions);
  return `${formattedDate} ${formattedTime}`;
};

const Bets = () => {
  const { betHistory, fetchBetHistory, fetchRaceDetails } = useBetHistoryStore();
  const [currentPage, setCurrentPage] = useState(1);
  const betsPerPage = 10;
  const [betTypes, setBetTypes] = useState({});
  const betTypesRef = useRef({});

  useEffect(() => {
    fetchBetHistory();
  }, [fetchBetHistory]);

  const sortedBets = useMemo(() => {
    if (!Array.isArray(betHistory)) {
      console.error("betHistory is not an array:", betHistory);
      return [];
    }
    return betHistory.flatMap(bet => 
      bet.transactions.map(transaction => ({
        ...bet,
        transaction
      }))
    ).filter(bet => bet.transaction && bet.transaction.updatedAt)
    .sort((a, b) => {
      const aUpdatedAt = new Date(a.transaction.updatedAt);
      const bUpdatedAt = new Date(b.transaction.updatedAt);
      return bUpdatedAt - aUpdatedAt;
    });
  }, [betHistory]);

  const paginatedBets = useMemo(() => {
    const startIndex = (currentPage - 1) * betsPerPage;
    return sortedBets.slice(startIndex, startIndex + betsPerPage);
  }, [sortedBets, currentPage]);

  const totalPages = Math.ceil(sortedBets.length / betsPerPage);

  const loadBetTypes = useCallback(async () => {
    const types = { ...betTypesRef.current };
    const fetchPromises = paginatedBets.map(async (bet) => {
      if (!types[bet.id]) {
        const raceDetails = await fetchRaceDetails(bet.raceId);
        const raceNumber = raceDetails ? raceDetails.raceNumber : null;
        const code = bet.pool.code;
        if (raceNumber !== null) {
          switch (code) {
            case "W":
            case "FC":
              types[bet.id] = `${code} R${raceNumber}`;
              break;
            case "DD":
              types[bet.id] = `${code} R${raceNumber - 1}-R${raceNumber}`;
              break;
            case "XD":
              types[bet.id] = `${code} R${raceNumber - 2} & R${raceNumber}`;
              break;
            case "DD+1":
              types[bet.id] = `${code} R${raceNumber - 2}-R${raceNumber}`;
              break;
            case "PICK5":
              types[bet.id] = `${code} R${raceNumber - 4}-R${raceNumber}`;
              break;
            case "PICK6":
              types[bet.id] = `${code} R${raceNumber - 5}-R${raceNumber}`;
              break;
            case "WTA":
              types[bet.id] = `${code} R${raceNumber - 6}-R${raceNumber}`;
              break;
            default:
              types[bet.id] = "Unknown Bet Type";
          }
        } else {
          types[bet.id] = "Unknown Bet Type";
        }
      }
    });

    await Promise.all(fetchPromises);
    betTypesRef.current = types;
    setBetTypes({ ...types });
  }, [paginatedBets, fetchRaceDetails]);

  useEffect(() => {
    if (paginatedBets.length > 0) {
      loadBetTypes();
    }
  }, [paginatedBets, loadBetTypes]);

  const getRaceNumber = (betId, position) => {
    const betType = betTypes[betId];
    if (!betType) {
      return `R${position}`;
    }
    if (betType.startsWith("WTA")) {
      const match = betType.match(/R(\d+)-R(\d+)/);
      if (match) {
        const startRace = parseInt(match[1], 10);
        return `R${startRace + position - 1}`;
      }
    }
    if (betType.startsWith("W")) {
      const match = betType.match(/R(\d+)/);
      return match ? `R${match[1]}` : `R${position}`;
    }
    if (betType.startsWith("XD")) {
      const match = betType.match(/R(\d+) & R(\d+)/);
      if (match) {
        return position === 1 ? `R${match[1]}` : `R${match[2]}`;
      }
    }
    const match = betType.match(/R(\d+)-R(\d+)/);
    if (match) {
      const startRace = parseInt(match[1], 10);
      const endRace = parseInt(match[2], 10);
      const totalRaces = endRace - startRace + 1;
      if (position <= totalRaces) {
        return `R${startRace + position - 1}`;
      }
    }
    return `R${position}`;
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="bet-container">
      {paginatedBets.map((bet) => {
        const transactionType = bet.transaction.metadata.transaction_type.toUpperCase();
        let statusColor;

        switch (transactionType) {
          case "WINNER":
            statusColor = "#BEF631";
            break;
          case "BET":
            statusColor = "#FF8989";
            break;
          case "REFUND":
            statusColor = "#78A3FF";
            break;
          default:
            statusColor = "#000"; // Default color
        }

        return (
          <div key={bet.transaction.id} className="bet-transaction">
            <div className="bet-header">
              <span className="bet-date">{formatDate(bet.transaction.updatedAt)}</span>
              <span className="bet-ticket">
                Ticket No. <strong>{bet.id}</strong>
              </span>
              <span className="bet-status" style={{ color: statusColor }}>
                {transactionType === "WINNER" ? "WIN" : transactionType}
              </span>
            </div>
            <table className="bet-table">
              <thead>
                <tr>
                  <th style={{ width: '28%' }}>Bet Type</th>
                  <th style={{ width: '40%' }}>Bets</th>
                  <th style={{ width: '20%' }}>Amount Computation</th>
                  <th style={{ width: '25%' }}>Total Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="bet-type">{betTypes[bet.id] || "Loading..."}</td>
                  <td>
                    {bet.pool.code === "FC" ? (
                      <>
                        <div>
                          <strong>1st:</strong>
                          <div className="bet-line">
                            {bet.metadata
                              .filter((group) => group.some((runner) => runner.position === 1))
                              .flatMap((group) => group.filter((runner) => runner.position === 1))
                              .map((runner, j) => (
                                <div key={j} className="bet-circle">
                                  {runner.runnerNumber}
                                </div>
                              ))}
                          </div>
                        </div>
                        <div>
                          <strong>2nd:</strong>
                          <div className="bet-line">
                            {bet.metadata
                              .filter((group) => group.some((runner) => runner.position === 2))
                              .flatMap((group) => group.filter((runner) => runner.position === 2))
                              .map((runner, j) => (
                                <div key={j} className="bet-circle">
                                  {runner.runnerNumber}
                                </div>
                              ))}
                          </div>
                        </div>
                      </>
                    ) : (
                      bet.metadata.map((positionGroup, i) => (
                        <div key={i}>
                          <strong className="bet-race-number">{getRaceNumber(bet.id, positionGroup[0].position)}:</strong>
                          <div className="bet-line">
                            {positionGroup.map((runner, j) => (
                              <div key={j} className="bet-circle">
                                {runner.runnerNumber}
                              </div>
                            ))}
                          </div>
                        </div>
                      ))
                    )}
                  </td>
                  <td className="bet-formula">
                    {["DD", "XD", "FC", "DD+1", "W"].includes(bet.pool.code) 
                      ? `P${bet.initialAmount} x ${(bet.transaction.amount / bet.initialAmount).toFixed(3)}` 
                      : `P${(bet.transaction.amount / bet.multiplier).toFixed(2)} x ${bet.multiplier}`}
                  </td>
                  <td className="bet-amount-computation">
                    <div className="previous-balance">
                      <CurrencyFormatter value={bet.transaction.metadata.previous_balance || 0.00} />
                    </div>
                    {["DD", "XD", "FC", "DD+1", "W"].includes(bet.pool.code) 
                      ? <strong className="bet-amount-value" style={{ color: bet.transaction.type === 'debit' ? '#ff1616' : 'green' }}>
                          {bet.transaction.type === 'debit' ? '- ' : '+ '}
                          <CurrencyFormatter value={(bet.transaction.amount * bet.multiplier).toFixed(2)} />
                        </strong>
                      : <strong className="bet-amount-value" style={{ color: bet.transaction.type === 'debit' ? 'red' : 'green' }}>
                          {bet.transaction.type === 'debit' ? '- ' : '+ '}
                          <CurrencyFormatter value={bet.transaction.amount.toFixed(2)} />
                        </strong>}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="bet-balance">
              Balance: <strong>
                <CurrencyFormatter value={bet.transaction.metadata.new_balance || 0.00} />
              </strong>
            </div>
          </div>
        );
      })}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default Bets;
