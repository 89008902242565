import React from "react";
import "./Load.css";
import loadLogo from "../../assets/Loading/kk-loading.svg";
import TopNavigation from "../TopNavigation/TopNavigation";
import BottomNavigation from "../../components/BottomNavigation/BottomNavigation";
import Footer from "../../components/Footer/Footer";

import Header from "../Header/BalanceHeader";
import { useMediaQuery } from "@mui/material";

const ComingSoon = () => {
  const isMobile = useMediaQuery("(max-width:768px)");
  return (
    <div className="loading-page">
      {isMobile ? <Header /> : <TopNavigation />}
      <div className="loading-container">
        <div className="loading-logo">
          <img src={loadLogo} alt="Karera King Logo" />
        </div>
        <div className="video-placeholder">
          <iframe
            className="video-frame"
            src="https://www.youtube.com/embed/4wa6VDVxJ5w"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="progress-bar">
          <div className="progress"></div>
        </div>
        <p className="loading-text">NO ACTIVE EVENT....</p>
      </div>
      {isMobile ? <BottomNavigation /> : <Footer />}
    </div>
  );
};

export default ComingSoon;
