import React, { useEffect } from "react";
import "./Header.css";
import Messenger from "../../assets/Header/messenger-logo.svg";
import { useAuthStore } from "../../store/useAuthStore";
import Telegram from "../../assets/Header/tg-logo.svg";
import { CurrencyFormatter } from "../../formatter/currencyFormatter";

const Header = () => {
  const { fetchUserProfile, user } = useAuthStore((state) => ({
    logout: state.logout,
    fetchUserProfile: state.fetchUserProfile,
    user: state.user,
  }));

  useEffect(() => {
    fetchUserProfile();

    // Set up polling to refresh user profile every 30 seconds
    const intervalId = setInterval(() => {
      fetchUserProfile();
    }, 3000); // 3 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [fetchUserProfile]);

  return (
    <header className="header-container">
      <div className="balance-section">
        <span>Balance:</span>
      </div>
      <div className="amount-section">
        <span>
          <CurrencyFormatter value={user ? user.balance : 0} />
        </span>
      </div>
      <div className="action-buttons-section">
        <a
          href="https://www.facebook.com/profile.php?id=61567510295878&mibextid=ZbWKwL"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Messenger} alt="Chat" className="chat-btn" />
        </a>
        <a
          href="https://t.me/+h1lh_ANBgg1jZDY1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Telegram} alt="telegram" className="telegram-btn" />
        </a>
      </div>
    </header>
  );
};

export default Header;
