import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import TopNavigation from '../../../components/TopNavigation/TopNavigation.jsx';
import BottomNavigation from '../../../components/BottomNavigation/BottomNavigation.jsx';
import Footer from '../../../components/Footer/Footer.jsx';
import Header from '../../../components/Header/BackHeader.jsx'; // Adjust the path as necessary
import ProfileTab from './components/ProfileTab';
import PasswordTab from './components/PasswordTab.jsx';
import './Profile.css'; // Assuming you'll have a CSS file for any necessary overrides

const ProfilePage = () => {
  const navigate = useNavigate(); // Initialize navigate hook
  const isMobile = useMediaQuery('(max-width:768px)');
  const handleBackClick = () => {
      navigate('/account'); // Navigate to the previous page in history
  };

  const [activeTab, setActiveTab] = useState('deposit');

  return (
      <div className="profile-page">
        {isMobile ? (<Header displayText='Profile' onBackClick={handleBackClick}/>) : (<TopNavigation />)} {/* Add the Header component here */}
          <div className="tabs-container">
            <div className="tabs">
              <button 
                className={`tab ${activeTab === 'deposit' ? 'active' : ''}`} 
                onClick={() => setActiveTab('deposit')}
              >
                MY PROFILE
              </button>
              <button 
                className={`tab ${activeTab === 'withdraw' ? 'active' : ''}`} 
                onClick={() => setActiveTab('withdraw')}
              >
                CHANGE PASSWORD
              </button>
            </div>
          </div>
      <div className='outer-container'>
      <div className="player-profile-page">
          {/* Conditional Rendering based on Active Tab */}
          {activeTab === 'deposit' && <ProfileTab />}
          {activeTab === 'withdraw' && <PasswordTab />}
      </div>
      </div>
      {isMobile ? (<BottomNavigation />) : (<Footer/>)}
      </div>
  );
};

export default ProfilePage;
