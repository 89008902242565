import React, { useState } from 'react';
import './TermsCondition.css';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import TopNavigation from '../../../components/TopNavigation/TopNavigation.jsx';
import BottomNavigation from '../../../components/BottomNavigation/BottomNavigation.jsx';
import Footer from '../../../components/Footer/Footer.jsx';
import Header from '../../../components/Header/BackHeader.jsx';

const TermsCondition = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:768px)');
    const [language, setLanguage] = useState('tagalog');

    const handleBackClick = () => {
        navigate('/account');
    };

    const handleLanguageChange = (e) => {
        setLanguage(e.target.value);
    };

    const termsContent = {
        english: [
            { title: "Effective Date", content: "November 08, 2024" },
            { title: "", content: "Welcome to Karera King! By using our platform to place bets on horse races, you agree to be bound by the following terms and conditions. Please read these carefully as they govern your use of our services and your rights. By accessing or using Karera King, you acknowledge that you have read, understood, and agree to these terms." },
            { title: "1. Account Registration", content: [
                "1.1. Accuracy of Information: You must provide accurate and up-to-date information when registering an account with Karera King. Failure to do so may result in suspension or termination of your account.",
                "1.2. Account Responsibility: You are solely responsible for maintaining the confidentiality of your login credentials. Any activity conducted under your account will be deemed to have been authorized by you."
            ]},
            { title: "2. Betting Rules", content: [
                "2.1. Types of Bets: Karera King offers various betting options, including but not limited to Win, Double (DD), Daily Double+1 (DD+1), Extra Double(XD), Pick5, Pick6, and Winner Take All (WTA). The rules for each bet type are available on the betting platform.",
                "2.2. Bet Confirmation: All bets placed are considered final once confirmed by the system. Bets cannot be modified or canceled after confirmation.",
                "2.3. Race Cancellations: In the event of a race cancellation, all bets related to that race will be void, and refunds will be issued according to the specific rules applicable to the bet type.",
                "2.4. Scratched Horses: If a horse is scratched (withdrawn) from a race, affected bets may either be refunded or adjusted based on the type of bet placed.",
                "2.5. Winning Limit (Koto): Karera King implements a Winning Limit, referred to as the 'Koto.' If the dividend amount of a winning bet exceeds the Koto, the player will only receive the maximum amount stated as the Koto, regardless of the actual dividend. The Koto amount represents the maximum winning limit, and once this limit is hit, no further winnings will be credited above that amount. The details of the Koto are specified in the Betting Rules section."
            ]},
            { title: "3. Payment and Withdrawals", content: [
                "3.1. Deposit and Wagering: Users must deposit funds into their Karera King account before placing any bets. All wagering will be made from your account balance.",
                "3.2. Withdrawals: Withdrawal requests will be processed within a reasonable timeframe. You may be required to verify your identity before withdrawals are approved.",
                "3.3. Fees: Karera King may impose fees for certain transactions, such as deposits, withdrawals, or account maintenance. Any applicable fees will be clearly communicated to you."
            ]},
            { title: "4. Prohibited Activities", content: [
                "4.1. Fraud and Cheating: Any form of cheating, collusion, or fraud is strictly prohibited and will result in account termination and forfeiture of any winnings.",
                "4.2. Use of Bots: The use of automated systems, bots, or any software designed to interfere with the betting process is prohibited."
            ]},
            { title: "5. Termination of Account", content: [
                "5.1. Breach of Terms: Karera King reserves the right to suspend or terminate your account if you violate any of these terms and conditions.",
                "5.2. Termination by User: You may terminate your account at any time by contacting customer support."
            ]},
            {title: "6. Age Limit", content: [
                "6.1. Age Limit: In the Philippines you have to be at least 21 years old to play in casinos. Republic Act No. 9287 is an act increasing the penalties for illegal number games (provides penalties and informer's reward), amending certain provisions of Presidential Decree No."
            ]},
            { title: "", content: "By using Karera King, you agree to abide by these terms and conditions. If you do not agree, please contact our customer service for your concerns." }
        ],
        tagalog: [
            { title: "Petsa ng Pagkakabisa", content: "Nobyembre 08, 2024" },
            { title: "", content: "Maligayang pagdating sa Karera King! Sa paggamit ng aming website upang tumaya sa mga karera ng kabayo, sumasang-ayon kang sundin ang mga sumusunod na tuntunin at kundisyon. Pakibasa nang mabuti ang mga ito dahil ito ang magtatakda ng paggamit mo sa aming mga serbisyo at ng iyong mga karapatan.. Sa pag-access o paggamit ng Karera King, kinikilala mong nabasa, naintindihan, at sumasang-ayon ka sa mga tuntuning ito." },
            { title: "1. Pagpaparehistro ng Account", content: [
                "1.1. Tamang Impormasyon: Dapat kang magbigay ng tamang at napapanahong impormasyon kapag nagrerehistro ng account sa Karera King. Ang pagkabigo na gawin ito ay maaaring magresulta sa suspensyon o pag-sara ng iyong account.",
                "1.2. Responsibilidad sa Account: Ikaw lamang ang responsable sa pagpapanatili ng kumpidensyalidad ng iyong login credentials. Anumang aktibidad na isinasagawa gamit ang iyong account ay ituturing na pinahintulutan mo."
            ]},
            { title: "2. Mga Alituntunin sa Pagtaya", content: [
                "2.1. Mga Uri ng Pusta: Nag-aalok ang Karera King ng iba’t ibang pagpipilian sa pagtaya, kabilang ngunit hindi limitado sa Win, Double (DD), Daily Double+1 (DD+1), Extra Double (XD), Pick5, Pick6, at Winner Take All (WTA). Ang mga patakaran para sa bawat uri ng pusta ay makikita sa plataporma ng pagtaya.",
                "2.2. Kumpirmasyon ng Pusta: Ang lahat ng pustang inilagay ay itinuturing na pinal kapag nakumpirma na ng sistema. Ang mga pusta ay hindi maaaring baguhin o kanselahin pagkatapos ng kumpirmasyon.",
                "2.3. Kanselasyon ng Karera: Sa kaganapan ng pagkansela ng isang karera, ang lahat ng pusta na may kaugnayan sa karerang iyon ay magiging walang bisa, at ang mga refund ay ibibigay alinsunod sa mga tiyak na patakaran para sa uri ng pusta.",
                "2.4. Mga Scratched na Kabayo: Kung ang isang kabayo ay scratched (inalis) mula sa isang karera, ang mga apektadong pusta ay maaaring ma-refund o ma-adjust batay sa uri ng pustang inilagay.",
                "2.5. Limitasyon sa Panalo (Koto): Ipinapatupad ng Karera King ang isang limitasyon sa panalo na tinatawag na 'Koto'. Kung ang halaga ng dibidendo ng isang nanalong pusta ay lumagpas sa Koto, makatatanggap lamang ang manlalaro ng pinakamataas na halagang itinakda bilang Koto, anuman ang aktwal na halaga ng dibidendo. Ang Koto ang nagsisilbing pinakamataas na limitasyon ng panalo, at kapag naabot na ang limitasyong ito, wala nang karagdagang panalo na maikekredito. Ang mga detalye ng Koto ay nakasaad sa seksyon ng Mga Alituntunin sa Pagtaya."
            ]},
            { title: "3. Bayad at Pag-withdraw", content: [
                "3.1. Deposito at Pagtaya: Kailangang magdeposito ang mga gumagamit ng pondo sa kanilang Karera King account bago makapaglagay ng pusta. Ang lahat ng pagtaya ay magmumula sa iyong account balance.",
                "3.2. Pag-withdraw: Ang mga kahilingan sa pag-withdraw ay ipoproseso sa loob ng makatwirang panahon. Maaaring kailanganin kang magbigay ng pagkakakilanlan bago aprubahan ang iyong pag-withdraw.",
                "3.3. Bayarin: Maaaring magpataw ang Karera King ng mga bayarin para sa ilang transaksyon, tulad ng mga deposito, pag-withdraw, o pagpapanatili ng account. Ang anumang naaangkop na bayarin ay malinaw na ipapaalam sa iyo."
            ]},
            { title: "4. Mga Ipinagbabawal na Aktibidad", content: [
                "4.1. Panloloko at Pandaraya: Ang anumang uri ng panloloko, pakikipagsabwatan, o pandaraya ay mahigpit na ipinagbabawal at magreresulta sa pagtatapos ng account at pagbawi ng anumang napanalunan.",
                "4.2. Paggamit ng Mga Bot: Ang paggamit ng mga automated na sistema, bot, o anumang software na idinisenyo upang makialam sa proseso ng pagtaya ay ipinagbabawal."
            ]},
            { title: "5. Pagbura ng Account", content: [
                "5.1. Paglabag sa Mga Tuntunin: Inilalaan ng Karera King ang karapatang suspindihin o wakasan ang iyong account kung nilabag mo ang alinman sa mga tuntuning ito.",
                "5.2. Pagwawakas ng Gumagamit: Maaari mong burahin ang iyong account anumang oras sa pamamagitan ng pakikipag-ugnayan sa aming customer support."
            ]},
            {title: "6. Limitasyon sa Edad", content: [
                "6.1. Limitation sa Edad: Sa Pilipinas, kailangan mong maging hindi bababa sa 21 taong gulang upang makapaglaro sa mga casino. Ang Republic Act Blg. 9287 ay isang batas na nagpapataas ng mga parusa para sa mga ilegal na larong numero (nagbibigay ng mga parusa at gantimpala sa impormante), na nag-aamyenda ng ilang mga probisyon ng Presidential Decree Blg."
            ]},
            { title: "", content: "Sa paggamit ng Karera King, sumasang-ayon kang sundin ang mga tuntunin at kundisyong ito. Kung hindi ka sumasang-ayon, mangyaring makipag-ugnayan sa aming customer service para sa iyong mga alalahanin." }
        ]
    };

    return (
        <>
            {isMobile ? (<Header displayText='Terms & Conditions' onBackClick={handleBackClick}/>) : (<TopNavigation />)}
            <div className="terms-condition-page">
                <div className="terms-condition-container">
                    <div className="language-selector">
                        <label className='language-label'>
                            Language:
                        </label>
                        <select value={language} onChange={handleLanguageChange}>
                            <option value="tagalog">Tagalog</option>
                            <option value="english">English</option>
                        </select>
                    </div>
                    <h4 className="terms-label">Terms & Conditions</h4>
                    <div className="rules-detail-container">
                        <div className="terms-condition-details">
                            {termsContent[language].map((section, index) => (
                                <div key={index} className="terms-section no-select">
                                    <h5>{section.title}</h5>
                                    {Array.isArray(section.content) ? (
                                        <ul>
                                            {section.content.map((item, idx) => (
                                                <li key={idx}>{item}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p>{section.content}</p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {isMobile ? (<BottomNavigation />) : (<Footer/>)}
        </>
    );
};

export default TermsCondition;