import React from "react";
import "./RaceDetails.css";

const RaceDetails = ({ arenaName, eventName }) => {
  return (
    <div className="race-details-container">
      <div className="race-details">
        <p className="race-details-text first">
        {arenaName}
        </p>
        <p className="race-details-text second">{eventName}</p>
      </div>
    </div>
  );
};

export default RaceDetails;
