import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@mui/material'; // Importing useMediaQuery from Material-UI
import TopNavigation from '../../components/TopNavigation/TopNavigation.jsx';
import BottomNavigation from '../../components/BottomNavigation/BottomNavigation.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import Header from '../../components/Header/BalanceHeader.jsx'; // Adjust the path as necessary
import Bets from './components/Bet.jsx';
import Wallet from './components/WalletTab.jsx';
import useEventsStore from '../../store/useEventStore';
import './Transaction.css'; // Assuming you'll have a CSS file for any necessary overrides

const TransactionPage = () => {
  const [activeTab, setActiveTab] = useState('deposit');
  const isMobile = useMediaQuery('(max-width:768px)');
  const { fetchActiveEvent } = useEventsStore();

  useEffect(() => {
    fetchActiveEvent();
  }, [fetchActiveEvent]);

  return (
    <div className="transaction-page">
      {isMobile ? (<Header />) : (<TopNavigation />)}
      <div className="transaction-tabs-container">
        <div className="transaction-tabs">
          <button 
            className={`transaction-tab ${activeTab === 'deposit' ? 'active' : ''}`} 
            onClick={() => setActiveTab('deposit')}
          >
            BETS
          </button>
          <button 
            className={`transaction-tab ${activeTab === 'withdraw' ? 'active' : ''}`} 
            onClick={() => setActiveTab('withdraw')}
          >
            WALLET
          </button>
        </div>
      </div>

      <div className="transaction-container">
        {activeTab === 'deposit' && <Bets />}
        {activeTab === 'withdraw' && <Wallet/>}
      </div>
      {isMobile ? (<BottomNavigation />) : (<Footer/>)}
    </div>
  );
};

export default TransactionPage;
