import React, { useState, useEffect } from 'react';
import { useMediaQuery } from "@mui/material";
import TopNavigation from "../../components/TopNavigation/TopNavigation.jsx";
import BottomNavigation from "../../components/BottomNavigation/BottomNavigation.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Header from "../../components/Header/BalanceHeader.jsx";
import Deposit from "./components/Deposit.jsx";
import Withdraw from "./components/Withdraw.jsx";
import useEventsStore from '../../store/useEventStore';
import "./PaymentPage.css";

const PaymentPage = () => {
  const [activeTab, setActiveTab] = useState("deposit");
  const isMobile = useMediaQuery("(max-width:768px)");
  const { fetchActiveEvent } = useEventsStore();

  useEffect(() => {
    fetchActiveEvent();
  }, [fetchActiveEvent]);

  return (
    <div className="payment-page">
      {isMobile ? <Header /> : <TopNavigation />}

      <div className="payment-tabs-container">
        <div className="payment-tabs">
          <button
            className={`payment-tab ${activeTab === "deposit" ? "active" : ""}`}
            onClick={() => setActiveTab("deposit")}
          >
            DEPOSIT
          </button>
          <button
            className={`payment-tab ${
              activeTab === "withdraw" ? "active" : ""
            }`}
            onClick={() => setActiveTab("withdraw")}
          >
            WITHDRAW
          </button>
        </div>
      </div>

      <div className="payment-method-page">
        {activeTab === "deposit" && <Deposit />}
        {activeTab === "withdraw" && <Withdraw />}
      </div>
      {isMobile ? <BottomNavigation /> : <Footer />}
    </div>
  );
};

export default PaymentPage;
