import React, { useState } from 'react';

const BetButton = ({ label, isActive, onClick, disabled }) => {
  const [touchStart, setTouchStart] = useState(null);

  const handleTouchStart = (event) => {
    const touch = event.touches[0];
    setTouchStart({ x: touch.clientX, y: touch.clientY });
  };

  const handleTouchEnd = (event) => {
    if (!touchStart) return;

    const touch = event.changedTouches[0];
    const deltaX = Math.abs(touch.clientX - touchStart.x);
    const deltaY = Math.abs(touch.clientY - touchStart.y);

    // Consider it a click if the touch movement is minimal
    if (deltaX < 5 && deltaY < 5) {
      handleClick(event);
    }

    setTouchStart(null);
  };

  const handleClick = (event) => {
    event.preventDefault();
    if (!disabled) {
      onClick(!isActive);
    }
  };

  return (
    <button
      onClick={handleClick}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      className={`bet-button ${isActive ? 'active' : ''}`}
      disabled={disabled}
      style={{
        width: '85px',
        height: '40px',
        marginLeft: '10px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 20px',
        borderRadius: '25px',
        border: '2px solid #A4C639',
        backgroundColor: disabled ? '#808080' : (isActive ? '#c9f62e' : 'transparent'),
        color: isActive ? '#242424' : '#D7D7D7',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        opacity: disabled ? 0.5 : 1,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '14px',
        fontFamily: 'Roboto Flex, sans-serif',
      }}
    >
      {label}
    </button>
  );
};

export default BetButton;
