import React, { useState } from "react";
import "../Profile.css";
import GradientButton from "../../../../components/Button/GradientButton";
import warningIcon from "../../../../assets/PlayerAccount/warning.svg";
import { useAuthStore } from "../../../../store/useAuthStore";

const PasswordTab = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const { updatePassword, error } = useAuthStore();

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      alert("New password and confirmation do not match.");
      return;
    }
    const success = await updatePassword(currentPassword, newPassword);
    if (success) {
      setShowSuccessPopup(true);
    }
  };

  const handleCloseSuccessPopup = () => {
    setShowSuccessPopup(false);
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  return (
    <div className="profile-method-container">
      <div className="warning-icon-container">
        <img src={warningIcon} alt="Warning" className="warn-icon" />
      </div>
      <span className="pass-note">
        Please do not share your password to keep your account safe.
      </span>
      <div className="password-container">
        <div className="pass-details">
          <div className="input-group">
            <label htmlFor="current-pass">Current Password</label>
            <input
              type="password"
              id="current-pass"
              placeholder="Current Password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="new-pass">New Password</label>
            <input
              type="password"
              id="new-pass"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="pass-validation">Confirm New Password</label>
            <input
              type="password"
              id="pass-validation"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        </div>
      </div>
      {error && <span className="warning-message">{error}</span>}
      <GradientButton
        style={{ marginTop: "5px", marginBottom: "16px" }}
        text="UPDATE"
        onClick={handlePasswordChange}
      />

      {showSuccessPopup && (
        <div className="success-popup">
          <div className="popup-content">
            <p>Password changed successfully!</p>
            <button onClick={handleCloseSuccessPopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PasswordTab;
