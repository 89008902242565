import { create } from "zustand";
import { useAuthStore } from "./useAuthStore";

const apiURL = process.env.REACT_APP_API_URL;

export const useBetHistoryStore = create((set) => ({
  betHistory: [],
  fetchBetHistory: async () => {
    const { token } = useAuthStore.getState();
    try {
      const response = await fetch(`${apiURL}/bet/history/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      set({ betHistory: data });
    } catch (error) {
      console.error("Failed to fetch bet history", error);
    }
  },
  fetchRaceDetails: async (raceId) => {
    const { token } = useAuthStore.getState();
    try {
      const response = await fetch(`${apiURL}/races/${raceId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return await response.json();
    } catch (error) {
      console.error("Failed to fetch race details", error);
      return null;
    }
  },
}));