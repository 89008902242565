import { create } from "zustand";
import { useAuthStore } from "./useAuthStore";
import useEventsStore from "./useEventStore";

const apiURL = process.env.REACT_APP_API_URL;

export const useResultsStore = create((set, get) => ({
  raceResults: [],
  racePositions: [],
  winRunners: {},
  loading: false,
  error: null,

  fetchRaceResults: async (raceNumber) => {
    const { token } = useAuthStore.getState();
    const { fetchActiveEvent, activeEvent } = useEventsStore.getState();
    set({ loading: true, error: null });

    try {
      // Fetch active event
      await fetchActiveEvent();

      if (!activeEvent) {
        throw new Error("No active event found");
      }

      // Find the race ID for the selected race number
      const race = activeEvent.races.find(r => r.raceNumber === raceNumber);
      if (!race) {
        throw new Error(`Race number ${raceNumber} not found`);
      }

      // Fetch race results
      const resultsResponse = await fetch(`${apiURL}/results/${race.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const results = await resultsResponse.json();

      // Map winners to their details
      const mappedResults = results.map(result => {
        return result.metadata.map(meta => ({
          betType: meta.type,
          winners: meta.winnerDetails,
          dividend: meta.dividend,
        }));
      });

      // Flatten the array of arrays
      set({ raceResults: mappedResults.flat(), racePositions: results.flatMap(result => result.racePosition) });
    } catch (error) {
      set({ error: error.message });
    } finally {
      set({ loading: false });
    }
  },
}));
